export default {
  "additionalQuestion": {
    "adultFemale": "Female",
    "adultMale": "Male",
    "adultOther": "Other",
    "answerQuestions": "Could you answer some additional questions?",
    "checkinTime": "Check-in time",
    "checkoutTime": "Check-out time",
    "moreDetailed": "We need to know a little more about your stay",
    "notificationFromFacility": "Notification from the facility",
    "selectTime": "Select time",
    "view": "View",
    "customizeYourStayExp": "Customize your stay experience",
    "customizeYourStayExpDesc": "Would you mind answering a few additional questions?"
  },
  "turnstile": {
    "unsupportedBrowser": "Your browser is not supported. Please use Chrome, Firefox, Safari, or Microsoft Edge."
  },
  "base": {
    "account": "Account",
    "all": "All",
    "apply": "Apply",
    "attachFile": "Attach file",
    "backTo": "Back to {0}",
    "booking": "Bookings",
    "browserWarning": "Your browser is not officially supported. If you find difficulty in the booking process, please upgrade your browser to the latest one.",
    "cancel": "Cancel",
    "close": "Close",
    "confirm": "Confirm your reservation",
    "continue": "Continue",
    "collapse": "Collapse",
    "delete": "Delete",
    "edit": "Edit",
    "error": "Something went wrong",
    "error02": "An error occurred during processing. Please try again later.",
    "errorPaymentMethod": "Cannot proceed the booking process for multiple rooms with different payment methods. Please choose your rooms with the same payment method or book your rooms in separated orders.",
    "oldErrorCode": {
      "1403": "This account has been registered by facility and required to reset password, please go back to the sign-in page and press \"forget your password\"."
    },
    "errorCode": {
      "1501": "You have reached the usage limit of this coupon and can't apply it again",
      "1903": "Reservations are accepted {reservable_period_months} months from today.\nPlease specify the reservation period. ",
      "2304": "The plan settings do not align with your account status for unknown reasons. Please kindly refresh the page and rebook again.",
      "2401": "You have only {currentPoint} points. Cannot spend {spendPoint} points.",
      "3001": "A timeout error has occurred. The payment has not been processed.",
      "3002": "The payment process has failed. ",
      "3003": "This card was not available.",
      "3004": "Please confirm the card number.",
      "3005": "Please make sure your expiration date is correct.",
      "3006": "3D Secure authentication failed.",
      "3007": "The credit card is not accepted or the card number is incorrect.",
      "3008": "3D Secure authentication has been cancelled.",
      "3009": "3D Secure authentication has been suspended due to multiple requests.",
      "3011": "A timeout error has occurred. The payment has not been processed.",
      "3013": "Updating this reservation is currently unavailable due to ongoing updates or payment processing. Please try again in approximately 5 minutes.",
      "3014": "As your original reservation content has been updated, please cancel and reconfirm the new content before updating it.",
      "3016": "Updating this reservation is currently unavailable due to ongoing updates or payment processing. Please try again in approximately 5 minutes.",
      "3202": "Your point balance is not available.",
      "3203": "An error occurred during processing. Please try again later.",
      "3204": "The payment process has failed, Please use another card, or retry later.",
      "3205": "This card was not available.",
      "3250": "3D Secure authentication failed.",
      "3251": "An error occurred during processing. Please try again later.",
      "3252": "The entered card is not compatible with 3D Secure.",
      "3502": "Your point balance is not available.",
      "3504": "Faild to point back, please confirm you point history.",
      "3750": "These dates are for the lottery event only. Please check the hotel website for more details.",
      "3751": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3752": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3753": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3754": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3756": "You've entered this lottery already. Please click on \"Reservations\" and navigate to the \"Lottery\" section of your member profile page to view your entries.",
      "3757": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3760": "This lottery event is not available at the moment, please check hotel website for more detail",
      "3780": "Your link is expired or invalid, please revisit the hotel site and re access this lottery event.",
      "3781": "Your link is expired or invalid, please revisit the hotel site and re access this lottery event.",
      "3782": "Your link is expired or invalid, please revisit the hotel site and re access this lottery event.",
      "3783": "Your link is expired or invalid, please revisit the hotel site and re access this lottery event."
    },
    "sessionExpired": "Session expired. Please refresh the page to continue.",
    "notAvailableYet": "The operation is not available yet. Please try again later.",
    "fileLimit80MB": "Maximum upload file size 80MB per image file",
    "upTo30FilesError": "Cannot attach more than 30 files",
    "upTo30Files": "Up to 30 files",
    "textLengthLimit": "Answer texts must be 255 characters or less.",
    "triplaCoLtd": "tripla Co., Ltd.",
    "triplaAi": "tripla.ai",
    "GDPR": "GDPR Policy",
    "here": "here",
    "imageError": "Image failed to load.",
    "join": "Sign up",
    "languages": {
      "en": "English",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "Searching...",
    "search": "Search",
    "login": "Sign in",
    "logout": "Sign out",
    "logoutMessage": "You have already signed out",
    "signInStatusChangeMessage": "The Sign in status has been changed and you will be redirected to the search page.",
    "memberSignIn": "Membership sign in",
    "memberSignUp": "Sign up",
    "next": "Next",
    "notSelected": "Not selected",
    "noData": "No data",
    "optional": "optional",
    "pay": "Pay",
    "previous": "Previous",
    "previousPage": "previous page",
    "privacyPolicy": "Privacy Policy",
    "readMore": "Read more",
    "readLess": "Read less",
    "recommended": "Recommended",
    "remove": "Remove",
    "required": "required",
    "requireMessage": "Required fields can't be blank.",
    "reservationStatuses": {
      "cancelled": "Cancelled",
      "done": "Reserved"
    },
    "save": "Save",
    "signInUp": "Sign-in / up",
    "submit": "Submit",
    "termError": "Please accept to the terms and conditions",
    "taxMessage": "The consumption tax rate is currently 8%, but is expected to be raised to 10% in October 2019. For accommodation bookings after October, please directly refer to the total amount on the reservation confirmation screen. We appreciate your cooperation and understanding.",
    "termsOfUse": "Terms of Use",
    "topPage": "Top page",
    "update": "Update",
    "success": "Success",
    "unsubscribe": "Unsubscribe",
    "unsubscribeConfirmation": {
      "error": "Error. Please try again later.",
      "title": "We're sorry to see you go. ",
      "description": "If you still wish to unsubscribe from our promotional emails, please click the button."
    },
    "unsubscribeSuccessDescribe": "You have unsubscribed. We won't send you our promotional emails anymore.",
    "warning": {
      "kidsChanged": "This facility does not support the specified search conditions, so the search conditions for children have been changed."
    },
    "formalName": "{name}",
    "maintenanceTitle": "The site is currently down for maintenance",
    "maintenanceDesc": "We apologize for any inconveniences caused. We’re almost done.",
    "noResultAvailable": "No results available",
    "taxAndServiceFeeIncluded": "Taxes and fees included"
  },
  "roomLottery": {
    "updateLottery": "Update your lottery entry",
    "restoreLottery": "Restore your lottery entry",
    "updateDisabledDesc": "Entry period of this lottery ends, please wait for the results.",
    "viewUpdateDetails": "View/Update details",
    "restoreUpdateEntry": "Restore/Update Entry",
    "updateProfileDesc": "After winning the lottery and make your reservation, the above information provided will be applied and cannot be changed.",
    "cancellationEmailDesc": "We've sent the cancellation email to {0}",
    "cancelModalTitle": "Cancel your lottery entry",
    "cancelModalDesc": "Do you want to cancel your lottery entry? This action cannot be undone.",
    "cancelEntry": "Cancel Entry",
    "reservationDeadlinePassed": "The reservation deadline has already passed",
    "reservationDeadlinePassedDesc": "We apologize, but the reservation deadline has already passed. For more details, please visit the hotel's website or contact us directly",
    "accountNotMatched": "This account does not match the one used when applying for the lottery. Please log in with the correct account.",
    "resultDateNotice": "The results will be announced on {date} ({day}) {time} ~",
    "resultDateNotice02": "Only the selected winner will receive another email to proceed with the actual booking process.",
    "resultDateNotice03": "You can always check the status under \"Reservations\" in the membership menu.",
    "lotteryEntryNumber": "Lottery entry number",
    "lotteryEntryConfirmed": "Your lottery entry has been confirmed",
    "lotteryEntryCancelled": "Your lottery entry has been cancelled",
    "lotteryResultNotSelected": "You are not selected for this lottery event.",
    "notSelectedNotice": "Thanks for join, we hope to see you next time.",
    "lotteryResultSelectedNotified": "🎉 You have been chosen as the WINNER!!",
    "lotteryWinnerNotice01": "※ Reservation has not been confirmed yet. Please complete the reservation procedure.",
    "lotteryWinnerNotice02": "Reservation deadline until {date} ({day}) {time}",
    "manageYourLottery": "Manage the lottery",
    "reservationIncomplete": {
      "errorMessage": "Other reservations are also being processed. Continuing with this reservation will cancel the others.",
      "checkboxMessage": "I agree to cancel the other reservations.",
      "modalMessage": "Since other reservation processes have already been completed, the reservation information will be displayed."
    }
  },
  "bookingDetail": {
    "overlappedReservation": "You have completed a {0} which overlaps the check-in and check-out date you are reserving now, please reconfirm before making reservation.\n*The reservation that is overlapped maybe is from other facility under this hotel brand.",
    "overlappedReservationLink": "reservation",
    "overlappedReservationGuest": "You have completed a reservation which overlaps the check-in and check-out date you are reserving now, please reconfirm before making reservation.\n*The reservation that is overlapped maybe is from other facility under this hotel brand.\n*if you have canceled the booking by other device before, this detection may be wrong, please confirm by the email you have received.",
    "overlappedReservationGuest2": "*if you have canceled the booking by other device or cleared the cookies, this detection may be wrong, please confirm by the email you have received."
  },
  "bookingResult": {
    "paymentPromotionBanner": {
      "message": "Enjoy an exclusive {0} discount when you pay with your credit card.",
      "limitedTimeOnly": "Offer valid for a limited time only!",
      "title2": "Pay with Your Credit Card & Save!"
    },
    "allUser": "All",
    "availableBetween": "Available Between",
    "bath": "Bath",
    "shower": "Shower",
    "bathtub": "Bathtub",
    "bedCount": "{n} bed | {n} bed | {n} beds",
    "book": "Book",
    "bookAirline": "Flight + Rooms",
    "flightRooms": "Flight + Rooms",
    "bookAirlineWarning": "{0} options are only available for check-in dates {1}. Please adjust your check-in date to access this option.",
    "bookAirlineWarningStay": "{0} options are available only for a maximum stay period of {1} days. Please adjust your stay period to access this option.",
    "cancellationFreeUntil": "Free cancellation until {0} (local time)",
    "cancellationPolicy": "Cancellation Policy",
    "discountAmount": "Discount Amount",
    "excludeDuring": "Exclude During",
    "forNights": "for {n} night | for {n} night | for {n} nights",
    "freeWifi": "Free in-room WiFi",
    "followingSearchConditions": "There are rooms available on this date.",
    "guests": "Guests",
    "includes": "Includes",
    "planInformation": "Plan information",
    "lowestPriceInfo": "The lowest price may not be displayed on the calendar depending on the inventory status and discount conditions. Please check the actual price from the calendar.",
    "maxAdults": "The maximum number of adults is {n}.",
    "meals": "Meals",
    "memberExclusive": "Member exclusive",
    "membersOnly": "Members Only",
    "nonSmoking": "Non-smoking",
    "noRoomFound": "Sorry your search returned no available rooms.",
    "tryChanging": "Try changing your {0} or location.",
    "tryItem": "dates",
    "noRoomSelected": "You haven't selected any room",
    "noRoomsAvailable": "Sorry your search returned no available rooms.",
    "noRoomsAvailableDesc": "Try changing your dates or location.",
    "notAvailable": "Not available",
    "priceFor": "Price for",
    "priceForAdult": "{n} adult | {n} adult | {n} adults",
    "priceForChild": "{n} child | {n} child | {n} children",
    "priceForRoom": "Room",
    "paymentCreditCard": "Online payment by credit card",
    "paymentMethods": "Payment",
    "paymentMethod": {
      "on_site": "Onsite Payment",
      "credit_card": "Online payment by credit card",
      "on_site_credit_card_required": "Onsite Payment (Credit card registration is required)",
      "deferred_payment": "Deferred payment",
      "credit_card_full": "Full payment by credit card",
      "credit_card_deposit": "Deposit by credit card",
      "bank_transfer": "Bank transfer",
      "credit_card_krw_domestic": "Online payment (domestic Korean credit card)",
      "krw_domestic_description": "Lotte Cards are currently not available to use but will be available soon.",
      "credit_card_krw_international": "Online payment (international credit card)"
    },
    "paymentNewCreditCards": "Online Payment via new credit card",
    "paymentRegisteredCreditCards": "Online payment via registered credit card",
    "planDetails": "Plan details",
    "plansLimitForUpdating": "You can't select plans except for ones in the original reservations.",
    "price": "Price",
    "promotionMayChanged": "Promotion may be changed or closed without warning.",
    "registerCard": "Register this card",
    "regularRate": "Regular Rate",
    "reservationStartDate": "Reservation start date",
    "showRoomDetails": "Show room details",
    "roomDetails": "Room details",
    "roomFeatures": "Room information",
    "roomLeft": "No room left | Only {n} room left! | Only {n} rooms left!",
    "roomAvailable": "No room left | {n} room left | {n} rooms left",
    "roomEnough": "Available",
    "roomRate": "Rate",
    "rooms": "Rooms",
    "roomSize": "Room size",
    "roomSizeUnit": {
      "tatami_mats": "tatami mats"
    },
    "select": "Select",
    "selectNewDate": "Select new date",
    "serviceFeeDesc": "These prices include service fee implemented by the booking site.",
    "sleeps": "Guests",
    "smoking": "Smoking",
    "stopReservationUpdate": "Stop reservation update process",
    "stopLotteryEntryRestore": "Stop lottery restore process",
    "stopLotteryEntryUpdate": "Stop lottery update process",
    "targetUser": "Target User",
    "taxExcluded": "Tax excluded",
    "taxIncluded": "Tax included",
    "toilet": "Toilet",
    "totalPrice": "",
    "totalPriceForNights": "Total price for {n} night | Total price for {n} night | Total price for {n} nights",
    "viewAll": "View all",
    "viewClose": "View less",
    "viewGallery": "View all photos",
    "viewLess": "View less",
    "viewMore": "View all {n} plan | View all {n} plans",
    "viewAllRooms": "View all {n} room | View all {n} rooms",
    "wifi": "WiFi",
    "wifiOption1": "Not available",
    "wifiOption2": "Free",
    "wifiOption3": "Charge",
    "searchResults": "Search results:",
    "nPlans": "{n} plan | {n} plans",
    "nRoomTypes": "{n} room type | {n} room types"
  },
  "customAuth": {
    "failTitle": "Login failed.",
    "failDesc": "Login failed because membership information is not registered."
  },
  "cancellationPolicy": {
    "policyHeader": "The following cancellation fees will apply to your booking:",
    "policyLine": "{0} days before check-in:  {1}% of the rate.",
    "policyLineWithTime": "{0} day(s) before check-in, after {1}: {2}% of the rate.",
    "freePolicyLine": "{0} days before check-in:  free of charge.",
    "noShowLine": "No Show:  {0}% of the rate.",
    "cancelOnTheDay": "On check-in day:  {0}% of the rate.",
    "cancelOnTheDayFree": "On check-in day before {0}: free of charge.",
    "cancelOnTheDayCharge": "On check-in day after {0}: {1}% of the rate.",
    "uponLocalTime": "*All cancellation policy are calculated based upon local time.",
    "uponLocalTimeOTA": "All cancellation policy are calculated based upon local time.",
    "ifBookedElsewhere": "If you made reservation by other websites or travel agencies, please cancel it from the original source you used.",
    "specialRate": "Special cancellation rate as: {0}",
    "guest": "Guest"
  },
  "calendar": {
    "fullyBooked": "fully booked",
    "roomsLeft": "# Rooms left",
    "selected": "Selected",
    "unavailable": "Unavailable",
    "unavailableNow": "Unavailable now"
  },
  "corporateAdmin": {
    "admin": "Admin",
    "checkInDate": "Check in date",
    "download": "Download",
    "guestName": "Booking name",
    "hotelName": "Hotel name",
    "maxNumberStay": "Maximum number of stays",
    "maxNumberStays": "Maximum number of stays",
    "nMonths": "{n} Month | {n} Month | {n} Months",
    "nights": "Nights",
    "numberOfRooms": "Rooms",
    "remaining": "Remaining",
    "reservationData": "Reservation data",
    "reservationDataDesc1": "※ The usage details show the total number of nights booked using our website. Therefore, please note that other reservations (phone, etc.) are not reflected.",
    "reservationDataDesc2": "※ It may take several minutes from the time you make a reservation until the contents are reflected on this page. ",
    "reservationDataDesc3": "※ Changes / cancellations made outside of our website are not reflected on this screen.",
    "reservationDate": "Reservation date",
    "reservationNumber": "Reservation number",
    "reserved": "Reserved",
    "status": "Status",
    "title": "Corporate Admin",
    "totalPrice": "Rate",
    "period": "Period",
    "year": "Year"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "Enter your payment details",
    "addNewCard": "New credit card",
    "cardExpiration": "Expiration Date",
    "cardExpirationConfirm": "Please confirm the expiration date",
    "cardHolder": "Card Holder's Name",
    "cardHolderConfirm": "Please confirm the name of the card holder",
    "cardHolderFormatError": "Please enter your name in half-width Roman characters.",
    "cardHolderHelpOTA": "Enter full name as it appears on your card",
    "cardHolderHelp": "Enter full name printed in your card",
    "cardNumber": "Card Number",
    "cardNumberConfirm": "Please confirm the card number",
    "cardNumberHelp": "Enter your card number",
    "cardRequired": "Your credit card information is only used to guarantee your booking and charge cancellation fee based on the {0}.",
    "cardSecurityCode": "Security Code",
    "cardSecurityCodeConfirm": "Please confirm the security code",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "A 3 or 4 digit number located on the back of (Visa, Mastercard, JCB and Diners Club) or on the front of (American Express) cards.",
    "ccvExample": "It's a 3 or 4-digit number printed on the back (Visa, Mastercard, JCB) or front (American Express) of the card. It's used for online purchases to verify that you're the legitimate cardholder and prevent fraud.",
    "creditCard": "Credit card",
    "expiredError": "Please register a valid credit card before the check-out date.",
    "month": "Month",
    "payInAdvanceOTA": "Credit card payment is processed by {0}.",
    "payInAdvance": "Pay in advance by credit card, processed by {0}.",
    "payInAdvanceVoucher": "Payment in advance by credit card, processed by {0}.",
    "payByCreditCard": "The payment will be settled by the travel agent ({0}) at the time the reservation is completed.",
    "payByCreditCardConsent": "Your personal information will be shared with the travel agency, tripla Co., Ltd. By proceeding with this reservation, you agree to share your information with {0}, {1}, and {2}.",
    "tappayNote": "We use TapPay 2048-bit SLL transaction system. We do not save any customers card details. TapPay secure your credit card information by certified as compliant under PCI DSS at Service Provider Level 1.",
    "year": "Year",
    "cardBrand": "Card Type",
    "cardBrandMismatch": "The card number does not belong to the selected card type",
    "paymentOverMillionError": "VISA and Mastercard cannot be used for online payments over 1 million yen. Please use another card brand or choose another payment type.",
    "badCards": "Due to system changes, the following credit card cannot be used for new reservations. Please enter the card information again.",
    "gmoBadCards": "Due to system changes, we're temporarily disabling the option to save or select previous credit cards. For credit card payment, please kindly enter the card details each time. We apologize for any inconvenience and appreciate your understanding. ",
    "pontaVisaCheckbox": "When using Route Inn Hotels PontaVISA card, please select 'Route Inn Hotels PontaVISA Card' as the card brand. If you select 'VISA', bonus points will not be awarded.",
    "creditCardInvalid": "Invalid",
    "creditCardInvaildHoverText": "Due to system changes, the following credit card cannot be used for new reservations. Please enter the card information again.",
    "rrn": "Date of Birth (YYMMDD)",
    "firstTwoDigitOfPin": "First two digits of PIN",
    "payDirectlyOnsitePayment": "Pay directly at the hotel with onsite payment",
    "payByLocalCurrency": "Pay in local currency using your JCB, Amex, or Diners card, or directly at the hotel with onsite payment.",
    "exploreLocalCurrency": "Or explore other payment options in the local currency",
    "exploreOther": "Or explore other payment options"
  },
  "gmoPaypalOptions": {
    "null": "Select card type",
    "visa": "VISA",
    "ponta_visa": "VISA (Route Inn Hotels PontaVISA card)",
    "master": "Mastercard",
    "mastercard": "Mastercard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Diners Club"
  },
  "downloadPasswordModal": {
    "label": "Please enter an encryption password. The reservation will be zipped using the password"
  },
  "extraItems": {
    "error": {
      "extraNumber": "Please change the number of {name}. The number that can be reserved is {min} ~ {max}."
    }
  },
  "extras": {
    "dateSelectTitle": "Select Dates to Add Extras",
    "dateSelectBtn": "Proceed to Quantity",
    "goToGuestDetails": "Enter Guest Details",
    "stock": "Stock:",
    "selectableQuantity": "Selectable quantity",
    "remainingExtras": "Remaining extras"
  },
  "facilitySuggestion": {
    "distanceFrom": "Distance: {n}km from this facility",
    "from": "From",
    "header": "Other available rooms near this hotel",
    "soldOut": "Sold out. Please change your requirements and search again.",
    "soldOutDesc": "Your dates are popular. We've run out of rooms at this property! Try adjusting your dates or select another property. ",
    "visitHotelWebsite": "Visit Hotel Website"
  },
  "guestDetail": {
    "afterUpdate": "Updated",
    "bookingForSomeone": "I'm making a booking for someone else.",
    "brrDiscount": "Best room rate discount",
    "confirmUpdates": "Confirm updates",
    "confirmPayByCard": "Confirm Pay by Credit Card",
    "coupon": "Coupon",
    "couponApplied": "Applied",
    "couponAppliedNew": "Applied coupons:",
    "creansmaerdCouponApplied": "Currently coupon name {name} is applied.",
    "couponCode": "Coupon code",
    "couponDiscount": "Coupon discount",
    "couponExpired": "We are sorry but your coupon code is no longer valid.",
    "couponInvalid": "The coupon code you entered is incorrect.",
    "couponLimitReached": "This coupon {code} can no longer be used because the usage limit has been reached.",
    "couponMaxStays": "This coupon's discount can only be used for maximum of first {n} nights. Any nights after first {n} nights will be charged at a regular price.",
    "couponTargetPlans": "Coupon code target plans",
    "couponNotUse": "Do not use any coupon",
    "couponValidUntil": "Valid until {date}",
    "couponUsageQuantityLimit": "Only one coupon code can be applied at a time. Please remove the already added code to apply a new one.",
    "creditCardWarning": "Original payment transaction of your credit  card will be canceled and the new total payment amount will be charged to your credit card.",
    "availableCoupons": "Available Coupons",
    "viewMoreCoupons": "View more Coupons",
    "removeCoupon": "Remove",
    "day": "Day {n}",
    "email": "Email (Please allow to receive mails from {email})",
    "emailAgain": "Email (Confirmation)",
    "emailAgainConfirm": "\"Email\" and \"Email Confirmation\" do not match",
    "emailAgainHelp": "Enter your email address again",
    "emailConfirm": "Please enter a correct email address",
    "emailHelp": "Enter your email address",
    "extras": "Extras",
    "extraLeft": "{count} {unit} left",
    "extraOutOfStock": "Out of stock",
    "fullName": "Full name",
    "fullNameError": "Please provide full name in English",
    "goPreviousScreenConfirm": "Go back to the previous screen?",
    "goPreviousScreenDescription": "Please note that any information entered on the current page will not be saved.",
    "goPreviousScreenContinue": "Continue",
    "guestDetails": "Guest details",
    "guestName": "Guest name",
    "guestNameError": "Please provide full name in English",
    "guestNameHelp": "Enter your full name",
    "guestNameKanji": "Guest name (Kanji)",
    "guestStayName": "Guest name",
    "mainGuest": "major contact",
    "notes": "Notes",
    "originalPaymentAmount": "Original payment amount",
    "promotionDiscount": "Discount",
    "phone": "Phone",
    "phoneConfirm": "Please enter a correct phone number. Only numbers and + are allowed.",
    "phoneHelp": "Enter your phone number",
    "priceBecomeCheaper": "The prices of the plans you selected have become cheaper now because the hotel updated promotions. Please check the updated price and proceed with your booking.",
    "priceBecomeExpensive": "The prices of the plans you selected got more expensive now because the hotel updated promotions. Please check the updated price carefully and proceed with your booking.",
    "priceDiff": "Price difference",
    "signupTerms": "This booking system is provided by tripla. Your personal information will be shared with tripla. By proceeding with this membership registration, I agree to tripla's {0}, {1} and {2}.",
    "terms": "By completing this reservation, you acknowledge that your personal information will be shared with tripla Co., Ltd, the company providing this reservation system. You agree to share your information with tripla {0}, {1}, and {2}.",
    "totalAmount": "Total amount",
    "totalAmountWithTax": "Total amount (Tax included)",
    "totalWithoutTax": "Total amount (Tax excluded)",
    "updateUserInformation": "Update my membership profile with the changes above except for the email.",
    "cheaperAccommodationTax": "The accommodation tax for your reservation has become cheaper now since you've redeemed your points. Please check the updated price and proceed with your booking",
    "roomGuestName": "Room {0} Guest Name",
    "confirmation": {
      "title": "Fields marked with an {0} are required to complete the form.",
      "description": "The guest's name cannot be changed after the reservation has been made. Please make sure to enter the name of the person who will be staying."
    }
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: Rooms available",
    "unavailableIconDescription": "{icon}: No rooms available",
    "uncertainIconDescription": "{icon}: Limited availability"
  },
  "lineLogin": {
    "buttonText": "Log in with LINE"
  },
  "meta": {
    "facilities": "Brand Facilities",
    "sign_in": "Sign in",
    "organization_sign_in": "Corporate sign in",
    "sign_up": "Sign up",
    "search_results": "Search Results",
    "guest_detail": "Guest Detail",
    "payment_method": "Payment",
    "lotteryCompleted": "Lottery Entry Confirmation",
    "completed": "Confirmation",
    "reservationIncomplete": "Incomplete Reservation",
    "my_account": "My Page",
    "otaHome": "smart hotel choices with AI-powered search",
    "otaHomeDescription": "Explore 2000+ handpicked accommodations with tripla, powered by AI search. From traditional ryokans to luxurious resorts, find your perfect stay effortlessly.",
    "otaSearchHotels": "Hotel Search Results",
    "otaSearchResults": "Search Results",
    "otaGuestDetail": "Guest Detail",
    "otaPayment": "Payment",
    "otaConfirmation": "Confirmation",
    "otaBookingConfirmation": "Booking Confirmation"
  },
  "membership": {
    "promotionRate": "Promotion rate",
    "regularRate": "Regular Rate",
    "signUpFor": "Membership Rate"
  },
  "membershipForm": {
    "address": "Address",
    "alreadyHaveAccount": "Already have an account",
    "back": "Back",
    "buildingName": "Building Name and room number",
    "changePassword": "Change Password",
    "changePasswordResult": "Your password has been changed",
    "changePasswordResult02": "✓ Successfully updated your password.",
    "changePasswordResult03": "Click Sign in to sign in using our new password",
    "changePasswordSecondary": "Please enter your new password",
    "confirmDeletedResult": "Your account has successfully been removed",
    "confirmNewPassword": "Confirm new password",
    "confirmPassword": "Confirm password",
    "confirmRegisterFail": "Something went wrong",
    "confirmRegisterFail02": "Please register again",
    "confirmRegisterResult": "E-mail address authentication",
    "confirmRegisterResult02": "✓ Successfully registered",
    "confirmRegisterResult03": "Enjoy your stay",
    "createAccount2": "Create your account",
    "createAccount3": "Create Account",
    "createAccount4": "create a new account",
    "dob": "Date of birth",
    "email": "Email",
    "emailError": "Please confirm your email",
    "emailPromotionCheck": "Receive promotional emails from the hotel.",
    "english_error": "Please input in English.",
    "locale_error": {
      "ja": "Please input in English.",
      "zh_Hant": "Please input in English.",
      "ko": "Please input in English."
    },
    "enterDetail": "Enter your detail below.",
    "first_name": "First name",
    "firstNameLocales": {
      "ja": "First name",
      "zh_Hant": "First name",
      "ko": "First name"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "John",
    "firstNamePlaceHolderLocales": {
      "ja": "John",
      "zh_Hant": "John",
      "ko": "John"
    },
    "firstName": "First Name",
    "firstNameError": "Please confirm your first name",
    "firstNameKana": "First Name",
    "forgotPassword": "Forgot your password",
    "forgotPassword02": "Reset password",
    "forgotPasswordError": "No user matched provided email.",
    "forgotPasswordResult": "Reset password",
    "forgotPasswordResult02": "✓ Password reset email has been sent.",
    "forgotPasswordResult02New": "✓ Password reset request received for {email}.",
    "forgotPasswordResultSecondary": "A password reset email has been sent to the email address on file for your account, but may take several minutes to show up in your inbox. Please wait at least 10 minutes before attempting another reset.",
    "forgotPasswordResultSecondaryNew": "If the email you entered is associated with an account, you will receive a password reset email shortly. It may take up to 10 minutes for the message to arrive. Please check your spam or junk folder before requesting to resend the email or attempting another reset.",
    "forgotPasswordResultSecondary02New": "If you don't receive an email within 10 minutes, you may not have an account. Try another email or {0}.",
    "forgotPasswordSecondary": "Please enter your email address. We will send a reset link to your email account.",
    "gender": "Gender",
    "generalError": "An error occurred during processing. Please try again later.",
    "guest_first_name": "First name",
    "guest_first_name_kanji": "",
    "guest_last_name": "Last name",
    "guest_last_name_kanji": "",
    "header": "Create Account",
    "hiragana_error": "",
    "kanji_katakana_error": "Please input Kanji, Hiragana, Katakana or English",
    "kanji_error": "",
    "last_name": "Last name",
    "lastNameLocales": {
      "ja": "Last name",
      "zh_Hant": "Last name",
      "ko": "Last name"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "Smith",
    "lastNamePlaceHolderLocales": {
      "ja": "Smith",
      "zh_Hant": "Smith",
      "ko": "Smith"
    },
    "lastName": "Last Name",
    "lastNameError": "Please confirm your last name",
    "lastNameKana": "Last Name",
    "membershipId": "Membership Number",
    "membershipProgram": "Membership program",
    "name": "Name",
    "nameJaPlaceholder": "Please input Kanji, Hiragana, Katakana or English.",
    "nameJaValidation": "Please input Kanji, Hiragana, Katakana or English.",
    "nameInvalidCharMessage": "The input you provided contains characters that is not supported by our system. ",
    "nameKanaJaPlaceholder": "Please input Hiragana.",
    "namePlaceholder": {
      "en": "Please input English.",
      "ja": "Please input Hiragana or English.",
      "zh_Hant": "Please input Kanji or English.",
      "zh_Hans": "Please input Kanji or English.",
      "ko": "Please input Hangul or English."
    },
    "nameValidation": {
      "ja": "Please input Hiragana or English.",
      "zh_Hant": "Please input Kanji or English.",
      "zh_Hans": "Please input Kanji or English.",
      "ko": "Please input Hangul or English."
    },
    "nationality": "Nationality",
    "newPassword": "New password (at least 8 characters with uppercase and lowercase alphabets and digits.)",
    "noAccount": "You don't have an account yet?",
    "occupation": "Occupation",
    "or": "Or",
    "orContinueBelow": "Or continue below",
    "password": "Password",
    "passwordConfirmationError": "Your password and its confirmation do not match.",
    "passwordError": "Please confirm your password",
    "passwordSignUp": "Password (at least 8 characters with uppercase and lowercase alphabets and digits.)",
    "passwordNote": "at least 8 characters with uppercase and lowercase alphabets and digits.",
    "pleaseSelect": "Select program",
    "postCode": "Postal Code",
    "postCodeError": "Only alphabets and numbers are allowed.",
    "prefecture": "Prefecture",
    "notApplicable": "Not Applicable",
    "privacyPolicy": "privacy policy",
    "promotionsCheckbox": "I want to receive special offers and promotions by email",
    "resend": "Resend",
    "resendConfirmationEmail": "Didn't receive confirmation email?",
    "resendConfirmationEmailError": "This email has not been registered before.",
    "resendConfirmationEmailTitle": "Resend confirmation email",
    "receiptName": "Receipt Name",
    "receiptUnicode": "",
    "send": "Send",
    "setPassword": "Set Password",
    "setup": "Create Account",
    "setupResultSecondary01": "If you would like to retry signing up membership registration. Please try again from {0}",
    "setupResultSecondary02": "We have sent an email to confirm the email address you registered.\nSince this is a temporary registration, please access the URL in the email to complete the membership registration.\nYou are able to log in after you complete the registration.",
    "signIn": "Sign in",
    "signInHere": "sign in here",
    "signInTo": "Sign in to {0}",
    "signUp": "Create an account",
    "signUpResult": "Confirm your email address",
    "signUpResult02": "Successfully registered",
    "signUpResult02Secondary": "Thanks for signing up to {n}.",
    "signUpResult02Secondary02": "A confirmation email has been sent to the email address you supplied.",
    "signUpResultSecondary": "Thanks for signing up to {n}.",
    "signUpResultSecondary02": "To use your account, you need to confirm the email address you supplied.",
    "signUpResultSecondary03": "Please check the content and click the following URL.",
    "signUpResultSecondary04": "Membership registration will be completed after confirming your email address.",
    "signUpSecondary": "Create your account, it takes less than a minute.",
    "term": "By signing up, you agree to {0} and {1}.",
    "termError": "Please accept to the terms and conditions",
    "termOfUse": "terms of use",
    "wifi": "Free in-room wifi",
    "yetReceived": "Didn’t receive the email? {0}",
    "resendEmail": "Resend Email",
    "pleaseResetPassword": "Registration of your email account has been confirmed, please note that there is no need to resend a confirmation email.\nIf you are aware of your password, kindly {0} directly. \nAlternatively, if you have forgotten your password, please use the {1} option. ",
    "pleaseResetPasswordLink1": "log in",
    "pleaseResetPasswordLink2": "password reset",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "Target membership"
  },
  "multipleCurrency": {
    "JPY": "Japanese Yen",
    "ARS": "Argentine Peso",
    "AUD": "Australian Dollar",
    "BRL": "Brazilian Real",
    "CAD": "Canadian Dollar",
    "CNY": "Chinese Yuan",
    "CZK": "Czech Koruna",
    "DKK": "Danish Krone",
    "EUR": "Euro",
    "HKD": "Hong Kong Dollar",
    "INR": "Indian Rupee",
    "IDR": "Indonesian Rupiah",
    "ILS": "Israeli New Shekel",
    "MOP": "Macau Pacata",
    "MYR": "Malaysian Ringgit",
    "MXN": "Mexican Peso",
    "TWD": "New Taiwan Dollar",
    "NZD": "New Zealand Dollar",
    "NOK": "Norwegian Krone",
    "PHP": "Philippine peso",
    "GBP": "Pound Sterling",
    "QAR": "Qatari Riyal",
    "RUB": "Russian Rouble",
    "SAR": "Saudi Arabian Riyal",
    "SGD": "Singapore Dollar",
    "ZAR": "South African Rand",
    "KRW": "South Korean Won",
    "LKR": "Sri Lankan Rupee",
    "SEK": "Swedish Krona",
    "CHF": "Swiss Franc",
    "THB": "Thai Baht",
    "AED": "United Arab Emirates Dirham",
    "USD": "United States Dollar",
    "VND": "Vietnamese Dong",
    "modalTitle": "Select your currency",
    "modalDescription": "Please select a currency. If you select a currency other than Japanese yen, your payment method will be limited to credit card.",
    "suggestedCurrencies": "Commonly used currencies",
    "allCurrencies": "All currencies",
    "creditCardWarning": "The currency has changed due to a change in payment method. Please check the amount.",
    "exchangeRateUpdatedError": "Exchange rates have been updated, so check the latest prices and make your reservation.",
    "currency": "Currency"
  },
  "myAccount": {
    "availablePoints": "Available {0}",
    "availablePointsDesc": "The total amount of {0} you currently hold and can spend.",
    "totalRankingPoints": "You have {amount} ranking {pointName}.",
    "changeLoginEmail": "Change login email",
    "changeLoginEmailDescription": "To update your email please change it below. You will be sent a verification email. If you have any problems receiving the email you can change your email address and try again as many times as you like.",
    "changeLoginEmailPendingText": "Your email address has been updated and is pending verification",
    "changePassword": "Reset password",
    "confirmLoginEmail": "Pending new email",
    "edit": "Edit",
    "emailLabel": "Email",
    "loginEmail": "Login Email",
    "loginInfo": "Login Information",
    "notRegistered": "Not registered",
    "pageTitle": "Account",
    "password": "Password",
    "passwordLabel": "Password",
    "paymentInfo": "Payment Information",
    "paymentOptions": "Payment Options",
    "paymentOptionsDesc": "Here is the list of your registered credit cards. All information related to credit cards will be managed with strict confidentiality.",
    "pendingEmailText": "{n} is pending verification",
    "personalInfo": "Personal Information",
    "promotions": "Coupons",
    "point": "Points",
    "removeAccount": "Remove Account",
    "removeAccountConfirmationText": "You cannot recover you account once deleted. Are you sure?",
    "removeAccountReason01": "Not use anymore",
    "removeAccountReason02": "Room charge is expensive",
    "removeAccountReason03": "Membership benefit is not attractive",
    "removeAccountReasonInput": "Type in your reason",
    "removeAccountReasonOthers": "Others (Please type in your reasons)",
    "removeAccountSecondaryText": "Would you like to remove your account?",
    "removeAccountSecondaryText02": "Please note this is not reversible",
    "removeCreditCard": "Remove Credit Card",
    "removeCreditCardConfirm": "Are you sure you want to remove this credit card?",
    "resendEmail": "Resend verification",
    "resendEmailSuccess": "An email has been sent to your inbox.",
    "yourCreditCards": "Your credit cards",
    "membershipId": "Membership ID",
    "myQRCode": "My QR code",
    "promotion": "Promotion email setting",
    "historyStatus": {
      "expired": "Expired Points",
      "future": "Future Points",
      "spent": "Spent Points",
      "gained": "Gained Points",
      "refunded": "Refund Points"
    },
    "value": "Value",
    "badCards": "Due to system changes, this card cannot be used for new reservations.",
    "unlockRank": "Unlock {rank} rank by amassing {points} {pointName} before {date}.",
    "unlockRankNoDate": "Unlock {rank} rank by amassing {points} {pointName}.",
    "earnMorePointsToKeepRank": "Earn {points} more {pointName} before  {date} to keep {rank} rank.",
    "achievedHighest": "Congratulations! You have achieved the highest rank.",
    "achievedHighestOnDate": "Congratulations! You have met the conditions and will achieve the highest rank on {date}."
  },
  "myProfile": {
    "cancel": "Cancel your booking",
    "cancelled": "CANCELLED",
    "change": "Change",
    "checkIn": "Check in",
    "checkOut": "Check out",
    "confirmationNo": "Reservation number",
    "multiRoom": "MULTI ROOMS",
    "myReservations": "Reservations",
    "myPage": "My Page ",
    "noResultButtonText": "Start planning your trip",
    "noResultText": "You haven't booked anything yet.",
    "pageTitle": "Your account",
    "viewDetails": "View Details",
    "viewFlightDetails": "Booking details (incl. plane ticket)",
    "myVoucher": {
      "amount": "Amount",
      "askConsume": "Consume coupon?",
      "askConsumeMessage": "This coupon can only be used once.",
      "cancel": "Cancel",
      "consume": "Consume",
      "consumeVoucher": "Consume Coupon",
      "excludeDuring": "Exclude during",
      "makeReservation": "Make Reservation",
      "targetStayingPeriod": "Target staying period",
      "validThrough": "Valid through",
      "name": "Name",
      "consumptionTime": "Consumption time",
      "reservationNumber": "Reservation number",
      "status": {
        "status": "Status",
        "available": "Available",
        "pending": "Pending",
        "expired": "Expired",
        "consumed": "Consumed"
      },
      "selectHotel": "Select Hotel"
    },
    "lottery": {
      "status": {
        "registered": "In Progress",
        "not_winner": "Not Selected",
        "winner_reservation_confirmed": "Selected(Reserved)",
        "winner_reservation_cancelled": "Reservation Cancelled",
        "registration_cancelled": "Entry Cancelled",
        "winner_reservation_pending_declined": "Entry Cancelled",
        "winner_reservation_pending": "Selected (Pending Confirmation)"
      },
      "text": "Lottery"
    },
    "processBooking": "Process Booking",
    "cancelEntry": "Cancel Entry",
    "lotteryEntryNo": "Lottery entry number"
  },
  "mcp": {
    "chooseCurrency": "Choose your preferred currency",
    "recommendLocalCurrency": "We recommend selecting VISA or Mastercard to pay in your local currency and avoid additional fees. Payments with other credit cards are currently not supported for multi-currency transactions."
  },
  "organization": {
    "enterDetail": "Please enter your account ID and password.",
    "signIn": "Corporate sign in"
  },
  "ota": {
    "changeLoginEmailPendingText": "Your email address has been updated and is pending verification",
    "name": {
      "agoda": "Agoda",
      "booking_com": "Booking com",
      "expedia": "Expedia",
      "jalan": "Jalan",
      "rakuten_travel": "Rakuten Travel",
      "rakutenTravel": "Rakuten Travel"
    },
    "priceNotAvailable": "Not available",
    "loadingTitle0": "Discovering Your Perfect Stay",
    "loadingDescription0": "Harnessing AI for personalized hotel suggestions. Your dream stay is loading...",
    "loadingTitle1": "Elevate Your Experience in Japan",
    "loadingDescription1": "Immerse yourself in Japan's rich culture by indulging in a traditional tea ceremony. Discover the art of matcha preparation and savor the tranquility.",
    "loadingTitle2": "Hidden Gems Await",
    "loadingDescription2": "Explore off-the-beaten-path wonders by venturing into lesser-known neighborhoods. Uncover local treasures and embrace the authentic charm of the city.",
    "loadingTitle3": "Connect with Tradition",
    "loadingDescription3": "Immerse yourself in the magic of geisha districts. Witness a traditional dance performance or engage in a tea ceremony for an authentic cultural encounter.",
    "loadingTitle4": "Culinary Delights",
    "loadingDescription4": "Dive into the diverse world of Japanese cuisine with a visit to different renowned street food markets. From savory sushi to delicious takoyaki, satisfy your taste buds.",
    "loadingTitle5": "Serenity in Nature",
    "loadingDescription5": "Escape the urban hustle by taking a stroll through enchanting bamboo groves. Experience the serenity and timeless beauty of this historical city.",
    "loadingTitle6": "Cherry Blossom Spectacle",
    "loadingDescription6": "Plan your visit during cherry blossom season to witness Japan's iconic landscapes bathed in hues of pink. Join locals in hanami celebrations for a truly magical experience.",
    "fromBwToOtaPaymentError": "Something went wrong. Please press the 'Next' button or select different payment method to try again.",
    "fromBwToOtaRestoreDataError": "Something went wrong. Please re-enter your data and press the 'Next' button again.",
    "searchHotels": "Search hotels",
    "aiRecommendations": "AI recommendations",
    "recommendationsForU": "Recommendations for you",
    "interestedIn": "Discover Alternative Recommendations",
    "home": "Home",
    "showingCountAvailable": "Showing {count} available out of {total} properties",
    "standardTravelAgency": "Business Condition",
    "termsOfUse": "Term of Use",
    "privacyPolicy": "Privacy Policy",
    "companyProfile": "Company Profile",
    "bookingSupport": "Booking Support",
    "search": "Search hotels",
    "checkAvailability": "Check Availability ",
    "avgPricePerNight": "Reference price per night",
    "adults": "{n} adult | {n} adult | {n} adults",
    "GDPR": "GDPR Privacy Policy",
    "homepage": {
      "recommend1": "Find me budget-friendly hotels in Tokyo for a weekend stay.",
      "recommend2": "Please help me find a resort hotel in Okinawa for three people.",
      "recommend3": "Explore Ryokan in Kyoto for my wife birthday.",
      "recommend4": "Next month, I will be on a business trip to Kobe. Are there any recommended hotels for solo accommodation?",
      "placeholder": "E.g. I would like to reserve a room for two people for three nights at a hotel near Shinjuku."
    },
    "bookingConfirmation": "Booking Confirmation",
    "confirmToOtaTitle": "Reservation Confirmation Process",
    "confirmToOtaDescription": "You will be redirected to the travel agency's site ({0}). Your reservation will be completed by completing the advance payment on the next page.",
    "confirmToOtaDescriptionInGuestForm": "You will be redirected to the travel agency's site ({0}). Your reservation will be completed by completing the advance payment on the next page.",
    "confirmToOtaDescriptionInGuestFormOnsiteCC": "You will be redirected to the travel agency's site ({0}). Your reservation will be completed by registering your credit card on the next page.",
    "aiSearchNoResult": "Our AI is currently in the learning process and wasn't able to generate relevant recommendations based on your input. Please provide more details, such as location, the number of people, facilities description, etc. Additionally, we have the following recommendations that you may find interesting."
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "Unfortunately, there are no rooms available that meet your requirements.",
    "noRoomsAvailableDesc": "Please choose other date, or change the number of guests to see if we have anything available for you.",
    "location": "Location",
    "showAllPhotos": "Show all photos"
  },
  "otaRoomsMap": {
    "title": "Map",
    "seeMap": "See Map"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "Online payment is processed by:",
    "paymentKRWCreditCardFooterLine1": "tripla Co., Ltd. (Korea branch)",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "Business registration number: 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "Please be sure to use the credit card of the person who made the reservation. If the cardholder's name does not match the reservation name, the reservation may be canceled without notice.",
    "paypalCheckbox2": "Cannot be used if the credit card issuer does not support 3D Secure.",
    "paypalCheckbox3": "The following personal information collected by our company will be provided to the card issuer you are using to detect and prevent unauthorized use. Name, phone number, email address, internet environment, etc.",
    "paypalCheckbox4": "If the card issuer you are using is in a foreign country, this information may be transferred to the country to which the issuer belongs.",
    "paypalCheckbox5": "Minors are permitted to use this service with the consent of the parent or guardian.",
    "pre_paid": "Credit Card Payment ",
    "credit_card": "Pre paid",
    "payments": {
      "credit_card": "Credit Card Payment "
    },
    "deferred_payment": "Deferred payment",
    "on_site": "On site",
    "on_site_credit_card_required": "On site",
    "type": "Payment type",
    "twd_credit_card": "Full payment by credit card",
    "twd_credit_card_deposit": "Deposit by credit card",
    "bank_transfer": "Bank transfer",
    "paymentPromotion": "Payment promotion",
    "paymentPromotionTooltip": "Promotion may be changed or closed without warning.",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "Available At",
    "cancellation": "Cancellation",
    "dateColumn": "Date",
    "descriptionColumn": "Description",
    "failToLoadErrorMessage": "I'm sorry. We've failed in loading your point history now.",
    "noData": "You don't have any point histories now.",
    "pointExpirationColumn": "Point Expiration",
    "pointHistory": "Point History",
    "pointsColumn": "Point Amount",
    "pointUse": "Point use",
    "stay": "Stay",
    "viewMore": "View more",
    "exchangeDigico": "{point} Exchange",
    "exchangeDigicoDesc": "Please enter the amount of {point} you want to exchange to Digico points. Digico points can be used to exchange your {point} to other point programs including Amazon, Apple, Google points. This process cannot be reveresed and Digico points are only valid for 180 days after the exchange. This service is only accessible within Japan. The facility cannot answer any questions about Digico points once it has been exchanged. For further information about Digico points, please refer to {link}.",
    "exchangeDigicoLink": "here",
    "exchangeDigicoLog": "Digico Point Exchange",
    "exchangeAvaliablePoints": "Total {point} Available",
    "exchangeBtn": "Exchange",
    "exchangeHistory": "{point} Exchange",
    "exchangedAmount": "Exchange amount",
    "exchangedDigicoCode": "Code",
    "exchangeConfirm": "This process cannot be reversed. Are you sure you want to confirm your {point} exchange?",
    "exchangeConfirmBtn": "Continue",
    "digicoPointUrl": "Digico Points Exchange URL:",
    "invalidAmount": "Please enter a valid point amount.",
    "totalBalance": "Total",
    "currentPoints": "Available Points",
    "futurePoints": "Future Points"
  },
  "pointSpending": {
    "balance": "Available points balance",
    "errors": {
      "incorrectAmount": "You can use points in increments of {n}.",
      "moreThanPrice": "You cannot use more points than total price.",
      "upToBalance": "You cannot use more points than your balance.",
      "pointUsageError": "It’s not allowed to proceed credit card payment when your total payment is 0.\nTo activate online payment, you could reduce spending points in previous step. "
    },
    "minimumAmount": "Minimum spending point amount must be {0} points or more.",
    "points": "Points",
    "saveForLater": "Save my points for later",
    "updateRule": "You cannot use points used for the original reservation. We will refund points for original reservation after the reservation has been updated.",
    "useAll": "Redeem my full point balance",
    "useAmount": "Redeem a specific amount",
    "title": "Points"
  },
  "points": {
    "naming": {
      "default": "Point",
      "history": "{point} History",
      "available": "Available {point}",
      "future": "Future {point}",
      "expired": "Expired {point}",
      "gained": "Gained {point}",
      "spent": "Spent {point}",
      "save": "Save my {point} for later",
      "redeem": "Redeem my full {point} balance",
      "redeem2": "Redeem the maximum amount of {point}",
      "refunded": "Refund {point}",
      "miniSpending": "Minimum spending {point} amount must be {number} or more.",
      "useIncrements": "You can use {point} in increments of {number}.",
      "discount": "{point} discount",
      "earn": "Earn {number} {point} by booking this room",
      "checkOut": "{point} will be added 3 days after the check-out date.",
      "recalculateOnCheckoutDate": "The displayed {point} amount is an estimate. The exact value will be recalculated on the checkout date.",
      "usePoint": "with {point}",
      "empty": "You don't have any {point} histories now.",
      "total": "Total",
      "availableBalance": "Available {point} balance",
      "redeemRemark": "Redeem a specific amount",
      "cannotUse": "You cannot use {point} used for the original reservation. We will refund {point} for original reservation after the reservation has been updated."
    },
    "getRegisterPoint": "Earn {amount} {points_name} for registering as a member"
  },
  "progressBar": {
    "completeLottery": "Complete Lottery Entry",
    "completeLotterySubtitle": "You have completed the lottery entry",
    "confirmationSubtitle": "Your room(s) is reserved",
    "confirmationSubtitleOTA": "Your room is reserved",
    "confirmationTitle": "Complete booking",
    "confirmationTitleOTA": "Confirmation",
    "guestSubtitle": "Fill in your details",
    "guestTitle": "Guest Details",
    "paymentSubtitle": "Select payment type",
    "paymentTitle": "Payment"
  },
  "reservation": {
    "cancelled": "Cancelled",
    "completed": "Completed",
    "upcoming": "Upcoming",
    "lottery": "Lottery",
    "filtersLabel": "Filter",
    "filters": {
      "all": "All",
      "onSiteChecked": "Onsite Payment",
      "on_site_credit_card_required": "Onsite Payment",
      "otaPrePaidChecked": "Credit Card payment by {0}",
      "bookingWidgetPrePaidChecked": "Online payment by credit card",
      "deferredPaymentChecked": "Deferred payment"
    },
    "reservedChannel": {
      "all": "All",
      "title": "Reserved Channel",
      "officialWebsite": "Official Website (Onsite Payment）",
      "wbf": "Dynamic Package",
      "tripla": "Travel booking website (credit card payment)"
    },
    "signInUpReminder": "Sign-in / up to receive membership benefits"
  },
  "reservationAction": {
    "contactTriplaSupport": "Contact tripla support",
    "applyGOTO": "Apply coupon code",
    "bookingCancel": "Cancel your booking",
    "bookingUpdate": "Update guest details",
    "corporateSignInRequired": "Corporate sign in is required to update your itinerary.",
    "differentFacilityWarning": "To renew your reservation, please log in through the facility you reserved.",
    "everythingCorrectSubtitle": "You can update your information or cancel this reservation below.",
    "everythingCorrectTitle": "Manage the reservation",
    "invoiceDownload": "Print Details",
    "madeMistakeHelp": "We look forward to serving you again soon.",
    "madeMistakeTitle": "Your booking has been cancelled.",
    "memberSignInRequired": "Membership sign in is required to update the reservation.",
    "pleaseCancelAll": "​If you want to cancel all the rooms, please cancel the reservation.",
    "payByCreditCard": "Pay by credit card ",
    "reductionOfNights": "Reduction of nights",
    "reductionOfRooms": "Reduction of rooms",
    "selectRoomsToCancel": "Please select the room you would like to cancel. ",
    "signOutRequired": "Please sign out to update the reservation.",
    "updateExpired": "This reservation can no longer be updated as it passed deadline.",
    "updateItinerary": "Update your itinerary",
    "differentAccountWarning": "Please sign-in with the correct account to update the reservation",
    "differentOrgWarning": "Please sign-in with the correct corporation to update the reservation",
    "coupon": {
      "signOutRequired": "Please sign out to apply the coupon.",
      "memberSignInRequired": "Membership sign in is required to apply the coupon.",
      "corporateSignInRequired": "Corporate sign in is required to  apply the coupon.",
      "differentFacilityWarning": "To apply the coupon, please log in through the facility you reserved.",
      "differentAccountWarning": "Please sign-in with the correct account to apply the coupon.",
      "differentOrgWarning": "Please sign-in with the correct corporation to apply the coupon."
    },
    "notEnoughStays": "The selected length of stay should be equal to or greater than the minimum length of stay of this reservation ({0} nights) . \n",
    "viewMyReservations": "View My Reservations",
    "madeMistakeHelp2": "We are looking forward to serving you again soon",
    "madeMistakeTitle2": "Booking Cancelled"
  },
  "reservationCancellation": {
    "roomN": "Room {0}",
    "cancellationFees": "Cancellation fees",
    "cancellationConfirm2": "Are you certain about cancelling your reservation?",
    "reason2": "Kindly share the reason for your cancellation",
    "reasonTripCancelled2": "Change in travel plans",
    "reasonRoomCheaper2": "Found a more affordable options elsewhere",
    "reasonNbTrvellers2": "Adjusted number of travelers",
    "reasonDifferentHotel2": "Opted for an alternative hotel",
    "reasonOther2": "Other (please specify)",
    "cancel": "Cancel Reservation",
    "cancellationConfirm": "Are you sure you want to cancel your reservation?",
    "cancellationFee": "Cancellation fee",
    "cancellationOnDay": "Cancellation on the day can not be done online.",
    "cancellationPolicy": "Cancellation Policy",
    "cancellationTitle": "Cancel your booking",
    "contactByChatPhone": "Please contact us by phone.",
    "contactByPhone": "Please contact us by phone.",
    "contactPhone": "Phone number {0}",
    "keep": "Keep Reservation",
    "nonRefundable": "Non-refundable",
    "reason": "Please provide us with a reason you are cancelling",
    "reasonDifferentHotel": "I found another hotel",
    "reasonNbTrvellers": "The number of travellers changed",
    "reasonOther": "Other",
    "reasonOtherHelp": "If other, please provide us with details",
    "reasonRoomCheaper": "The room is cheaper somewhere else",
    "reasonTripCancelled": "My trip is cancelled",
    "roomNbCancelFee": "Room {0} cancellation fee",
    "totalCancelFee": "Total cancellation fee"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "Your booking is confirmed",
    "cancelledSuccessfully": "Booking Cancelled Successfully",
    "totalStays": "Total length of stay",
    "contactProperty": "Contact property",
    "yourRoomDetails": "Your room details",
    "phone": "Phone",
    "email": "Email",
    "website": "Website",
    "cancellationFreeUntil": "Free cancellation until {0}",
    "cannotChangePlan": "You can't change the plans of the original reservation.",
    "confirmationEmail": "We've sent the confirmation email to {0}",
    "extra": {
      "answer": "Answer",
      "dateTime": "Date & Time",
      "extrasPrice": "Extras",
      "items": "Items",
      "notes": "Notes",
      "price": "Price",
      "priceIncludeTax": "Price (Tax included)",
      "question": "Question",
      "roomPrice": "Total room price"
    },
    "extrasSubtotal": "Subtotal of extras",
    "nonRefundableReminder": "This booking is non-refundable. Please be noted that no refund will be made on your cancellation.",
    "originalReservationPlans": "Plans of the original reservation",
    "reservationDetail": "Reservation Detail",
    "selectNewItinerary": "Please select your new itinerary and number of people.",
    "updateBooking": "Update your booking",
    "cannotUpdateTooltip": "As the reservation content or pricing has been modified by our facility staff previously in accordance with your agreement, you are unable to update/cancel the reservation."
  },
  "reservationDetail": {
    "bookingDetails": "Booking Details",
    "adult": "{n} Adult | {n} Adult | {n} Adults",
    "accommodationTax": "Accommodation tax",
    "checkIn": "Check-in date",
    "checkOut": "Check-out date",
    "child": "{n} Child | {n} Child | {n} Children",
    "dayUse": "Day-use",
    "details": "Details",
    "detailsTitle": "Reservation Details",
    "discount": "Discount",
    "discounts": "Discounts",
    "discountedPrice": "Point Discount",
    "guest": "{n} Guest | {n} Guest | {n} Guests",
    "guests": "Guests",
    "hotel": "Hotel",
    "maxGuests": "Max Guests",
    "night": "{n} Night | {n} Night | {n} Nights",
    "nights": "Nights",
    "numberOfGuest": "Rooms",
    "numberOfNights": "Nights",
    "numberOfPeople": "Number of People",
    "numberOfAdults": "{n} Adult | {n} Adult | {n} Adults",
    "numberOfChildren": "{n} Child | {n} Child | {n} Children",
    "originalPrice": "Total payment amount",
    "pointDiscount": "Point discount",
    "pointInstruction": "Points will be added 3 days after the check-out date.",
    "pointReward": "Earn {0} points by booking this room",
    "priceBreakDown": "Price details",
    "priceDetails": "Price Details",
    "reservationDetails": "Reservation Details",
    "reservationNumber": "Reservation number",
    "roomDetails": "Room details",
    "roomNb": "Room {0}",
    "roomPlan": "Plan name",
    "roomRate": "Room Rate",
    "roomType": "Room type",
    "roomWithChildren": "{nRoom} room(s) for {nAdult} adult(s) and {nChild} child(ren)",
    "roomWithoutChildren": "{nRoom} room(s) for {nAdult} adult(s)",
    "searchNecessary": "* This reservation number is necessary when you update / cancel the reservation or ask about the reservation to us.",
    "serviceFee": "Service Fee",
    "selected": "You selected",
    "subTotal": "Subtotal",
    "subTotalWithoutTax": "Subtotal (without tax)",
    "tax": "Tax",
    "taxIncluded": "Tax included",
    "total": "Payment Amount (Tax Included)",
    "totalAmount": "Total Amount",
    "totalLengthOfStay": "Total length of stay",
    "totalPriceWithTax": "Total price with tax",
    "depositAmount": "Deposit amount ({0}%)",
    "remainAmount": "Remaining amount",
    "withTax": "with tax",
    "depositNecessary": "You need to pay a deposit of {0}% via our online system to finalize the booking confirmation."
  },
  "reservationPayment": {
    "creditCardPayment": "Prepay by credit card",
    "onSitePayment": "Pay at hotel (No prepayment needed)",
    "paymentMethod": "Payment Method",
    "pendingPage": {
      "launchButton": "3D Secure",
      "text01": "Click the button to refer to the 3D Secure password entry screen.",
      "text02": "If the password entry is not completed within 15 minutes, the reservation process will be cancelled.",
      "text03": "The reservation is not completed until you enter your password, so the vacancy might be taken. ",
      "text04": "Credit card payments will be handled by tripla, so \"tripla\" will be displayed in the 3D Secure store name.",
      "timeOutError01": "A timeout error has occurred.",
      "timeOutError02": "The payment is not completed in the normal course. However, if any charges have been incurred on the credit card, it will be refunded. Please confirm the details of the payment and refund timeline with the credit card company.",
      "authError01": "The payment process has failed. ",
      "error01": "An error occurred during the reservation process.",
      "error02": "The payment is not completed in the normal course. However, if any charges have been incurred on the credit card, it will be refunded. Please confirm the details of the payment and refund timeline with the credit card company.",
      "error03": "Click the \"Back\" button and reserve again from the search result screen."
    },
    "planError": "Sorry, the selected plans have conflict payment types. Please try again.",
    "selectCreditCards": "Select a credit card",
    "sessionErrorMessage": "Something went wrong. Please return to Guest Details page and try again.",
    "sessionErrorButtonText": "Return to Guest Details",
    "gmoCreditCardMaintenanceWarning": "From 4:00 AM to 6:00 AM (Japan Time) on November 18, 2024, the card brand will not be available due to system maintenance. Please choose an alternative payment method or wait until maintenance is complete.",
    "gmoOnSiteCreditCardRequiredMaintenanceWarning": "From 4:00 to 6:00 AM (Japan Standard Time) on November 18, 2024, card registration will be unavailable due to system maintenance. Please choose an alternative payment method or wait until maintenance is complete.",
    "gmoMcpMaintenanceWarning": "From 4:00 to 6:00 AM (Japan Standard Time) on November 18, 2024, VISA and Mastercard cannot be used due to system maintenance. Please choose an alternative payment method or wait until maintenance is complete."
  },
  "reservationReceipt": {
    "customerName": "Customer name for receipt",
    "deferredWithoutDownload": "The receipt will not be issued for deferred payments.",
    "download": "Download receipt",
    "downloadOnCheckout": "Your receipt can be downloaded on the check-out date and anytime after.",
    "downloadAtSpecificTime": "Your receipt can be downloaded on {date} at {time} and anytime after.",
    "onSite": "For on-site payments, a receipt will be issued at the hotel's front desk."
  },
  "searchBar": {
    "addRoom": "Add another room",
    "adult": "{n} Adult | {n} Adult | {n} Adults",
    "adults": "Adults",
    "age": "Age",
    "agree": "Agree",
    "bed": "Bed",
    "checkIn": "Check in",
    "checkInAndOut": "Check in - check out date",
    "checkOut": "Check out",
    "child": "{n} Child | {n} Child | {n} Children",
    "childN": "Child {n}",
    "children": "Children",
    "childrenAgeAtCheckout": "Ages of children at checkout",
    "childrenAgeInfant": "Age {from}-{to}",
    "childrenAgePrimary": {
      "primary": "Age {from}-{to}",
      "primaryBottom": "Age {from}-{to}",
      "primaryTop": "Age {from}-{to}"
    },
    "childrenAges": "ages of the children you’re travelling with?",
    "childrenAgeSharedBed": "Age {from}-{to}",
    "childrenMealBedding": {
      "meal_no": "No meal & bed",
      "meal_no_bedding": "Bed only",
      "meal_same_as_adult_bedding": "Meal & bed",
      "meal_yes": "Meal only",
      "meal_yes_bedding": "Meal & bed"
    },
    "cookieConsent": "This website uses cookies to improve your user experience. By continuing to use this website, you have agreed with our cookie consent. For futher information, please check the {0}.",
    "privacyPolicy": "Private Policy",
    "perRoomGuests": "Number of guests per room",
    "dates": "Dates",
    "dayUse": "Book for day-use only",
    "defaultArea": "Any location",
    "edit": "Edit",
    "filter": "Filter",
    "guests": "Guests",
    "hotel": "Hotel",
    "maxChildrenWarning": "Maximum number of children per adult is {n}",
    "pleaseSelectHotel": "Please select hotel",
    "room": "{n} Room | {n} Room | {n} Rooms",
    "rooms": "Rooms",
    "search": "Search",
    "showOnlyAvailable": "Show only available",
    "sort": "Sort",
    "sortOption": {
      "price_high_to_low": "Price High to Low",
      "price_low_to_high": "Price Low to High",
      "recommended": "Recommended",
      "asc": "Price Low to High",
      "desc": "Price High to Low"
    },
    "to": "to",
    "undated": "Date undecided",
    "nights": "Nights",
    "yearOld": "{n} year old | {n} year old | {n} years old",
    "enableWBFSearch": "Enable Hotel + Flight Package Search ",
    "stayFlightTab": "Stay + Flight ",
    "stayTab": "Stay",
    "processInJapTxt": "The reservation process is available exclusively in Japanese.",
    "processMaxTxt": "The reservation process is available exclusively in Japanese, with a maximum of 5 rooms for Stay+Flight bookings.",
    "undatedWarning": "Searching without specifying the check-in date is not available if the number of people in each room is different. ",
    "undatedWarning2": "This is not available when searching with “Flight + Rooms”.",
    "flightAndRoomsWarning": "This is not available when searching with “Date undecided”."
  },
  "searchReservation": {
    "enterDetail": "Enter your detail below.",
    "enterReservationNumber": "Enter your reservation number",
    "manageBooking": "Manage your booking",
    "searchFailed": "We need the following information to continue. Unfortunately, we don't recognize the email or reservation number. Please try again.",
    "confirmReservation": "Confirm Reservation",
    "confirmReservationDesc": "As this booking is paid by {ota}, you will be redirected to {ota} in {time} seconds."
  },
  "searchResultByPlan": {
    "hide": "Hide",
    "planContent": "Plan description",
    "plans": "Plans",
    "rooms": "Rooms",
    "showPlan": "Show plan details",
    "moreDetail": "More details"
  },
  "snsLogin": {
    "loginWith": "Log in with {0}",
    "enterPassword": "Enter your membership account password to continue",
    "existingPrompt": "It looks like you have an existing account. Would you like to connect your account with {sns}? You will be able to log in both with {sns} and your normal email/password.",
    "signUpDescription": "Thank you for connecting your {sns} account, please provide the remaining information to complete account sign up."
  },
  "voucher": {
    "cancel": "Cancel",
    "confirm": "Confirm",
    "number": "Voucher number",
    "purchseComplete": "Purchase completed",
    "selectStay": "Please select your itinerary",
    "stillVoucher": "This is still a voucher. Check-in/out are not selected yet as a confirmed reservation.",
    "thankPurchase": "Thank you for your purchase.",
    "update": "Update",
    "updateItinerary": "Update your itinerary",
    "updateItineraryDesc": "Please select desired check-in date and check-out date to update the itinerary.",
    "usingVoucher": "Reserve the room using this voucher",
    "valid": "Voucher is now valid",
    "voucherDesc": "You can book the room using this voucher by selecting check-in/out date here.",
    "voucherDetails": "Voucher details",
    "voucherNumber": "Voucher number"
  },
  "triplaPay": {
    "notFoundMsg": "Payment record not found. Your transaction won't be completed.",
    "isPaid": "This payment is successfully completed",
    "isAlreadyPaid": "Already paid",
    "isFailed": "The payment is failed",
    "isCompleted": "The payment is completed",
    "isRefunded": "Your payment has been refunded",
    "refundedAt": "Refund date",
    "paidAt": "Payment date",
    "isCancelled": "The payment was cancelled",
    "total": "Payment Amount",
    "pleaseReload": "This work was not completed in one hour. Please reload.",
    "reload": "Reload",
    "subject": "Payment Subject",
    "amountIsChanged": "The payment amount has been changed, please click the \"Update\" button to refresh.",
    "terms": "By agreeing to tripla {0}, {1}, and {2}, you understand that this payment system is provided by tripla Co., Ltd. and that your personal information will be shared with tripla Co., Ltd.",
    "paypalTerms": "PayPal is used for credit card payments, and will be stated on your card statement.",
    "temsOfUse": "terms of use",
    "privacyPolicy": "Privacy Policy",
    "gdprPolicy": "GDPR Policy",
    "pleaseScan": "Please scan the QR code"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "Please review information that you have entered.",
    "INVALID_EXPIRATION_YEAR": "Please make sure your expiration date is correct.",
    "Invalid_card_number": "Please confirm the card number.",
    "Others": "Please review information that you have entered."
  },
  "lottery": {
    "registerForLottery": "Register for Lottery",
    "selectedDatesError": "The selected length of stay should be within the maximum and minimum length of stay allowed for this lottery event.  ( {0} ~ {1} nights) "
  },
  "recommenderWidget": {
    "previewText": "This is a preview mode, valid for only 5 minutes. Please click 'Preview' again after 5 minutes. Remember to save your settings for the actual live version."
  }
}